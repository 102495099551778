<template>
  <div class="Map">
    <!-- <baidu-map
      class="map"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      :mapStyle="mapStyle"
      :scroll-wheel-zoom="true"
    >
      <bm-polyline
        :path="siteVos.polylinePath"
        stroke-color="#33BFEB"
        :stroke-opacity="0.9"
        :stroke-weight="8"
        @lineupdate="updatePolylinePath"
      ></bm-polyline>
      <div v-for="(item, i) in siteVos" :key="i">
        <template>
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/zaixian.png'),
              size: { width: 35, height: 41 },
            }"
            :zIndex="9"
          >
          </bm-marker>
        </template>
      </div>
    </baidu-map> -->
    <baidu-map
      class="map"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      :scroll-wheel-zoom="true"
    >
    <bm-polyline
        :path="siteVos.polylinePath"
        stroke-color="#33BFEB"
        :stroke-opacity="0.9"
        :stroke-weight="8"
        @click="clickPolyline(siteVos[0].id)"
      ></bm-polyline>
          <bm-marker
           v-for="(item, i) in siteVos" :key="i"
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/zaixian.png'),
              size: { width: 35, height: 41 },
            }"
            :zIndex="9"
            animation="BMAP_ANIMATION_BOUNCE"
          >
          </bm-marker>
    </baidu-map>
    <div class="storage_Map_Table">
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF',
        }"
        style="height: 50%"
        :data="gridData"
      >
        <el-table-column
          type="index"
          prop="index"
          label="序号"
          width="95"
        ></el-table-column>
        <el-table-column prop="reachName" label="站点名称"> </el-table-column>
        <el-table-column prop="upstreamDepth" label="上游水深(cm)">
        </el-table-column>
        <el-table-column prop="upstreamWaterLevel" label="上游水位(cm)">
        </el-table-column>
        <el-table-column prop="downstreamDepth" label="下游水深(cm)">
        </el-table-column>
        <el-table-column prop="downstreamWaterLevel" label="下游水位(cm)">
        </el-table-column>
        <el-table-column prop="reachWidth" label="河底宽度(m)"> </el-table-column>
        <el-table-column prop="reachSlope" label="河道坡降"> </el-table-column>
        <el-table-column prop="reachLength" label="河段长度(m)"> </el-table-column>
        <el-table-column prop="estimationWaterYield" label="估算水量(m³)">
        </el-table-column>
        <el-table-column width="200" label="参数设置">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <el-button class="edit">配置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
const cityOptions = ["全选", "节制闸", "测流点"];
import { getRiverwayDataList } from "../../../api/index";
export default {
  data() {
    return {
      siteVos: [], // 站点列表
      center: {
        lng: 114.90745,
        lat: 34.848941,
      },
      zoom: 12,
      gridData: [],
      mapStyle: {
        styleJson: [
          {
            featureType: "land",
            elementType: "geometry",
            stylers: {
              color: "#f5f6f7ff",
            },
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: {
              color: "#26b6f0ff",
            },
          },
          {
            featureType: "green",
            elementType: "geometry",
            stylers: {
              color: "#dcf2d5ff",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffe59eff",
            },
          },
          {
            featureType: "highway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#f5d48cff",
            },
          },
          {
            featureType: "nationalway",
            elementType: "geometry.fill",
            stylers: {
              color: "#fff6ccff",
            },
          },
          {
            featureType: "provincialway",
            elementType: "geometry.fill",
            stylers: {
              color: "#fff6ccff",
            },
          },
          {
            featureType: "cityhighway",
            elementType: "geometry.fill",
            stylers: {
              color: "#fff6ccff",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry.fill",
            stylers: {
              color: "#fff6ccff",
            },
          },
          {
            featureType: "nationalway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#f2dc9dff",
            },
          },
          {
            featureType: "provincialway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#f2dc9dff",
            },
          },
          {
            featureType: "cityhighway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#f2dc9dff",
            },
          },
          {
            featureType: "arterial",
            elementType: "geometry.stroke",
            stylers: {
              color: "#f2dc9dff",
            },
          },
          {
            featureType: "building",
            elementType: "geometry.sidefill",
            stylers: {
              color: "#e6ebf0ff",
            },
          },
          {
            featureType: "building",
            elementType: "geometry.topfill",
            stylers: {
              color: "#e6ebf0ff",
            },
          },
          {
            featureType: "building",
            elementType: "geometry.stroke",
            stylers: {
              color: "#d8e2ebff",
            },
          },
          {
            featureType: "tertiaryway",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "tertiaryway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#dfe4ebff",
            },
          },
          {
            featureType: "fourlevelway",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "fourlevelway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#dfe4ebff",
            },
          },
          {
            featureType: "local",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "local",
            elementType: "geometry.stroke",
            stylers: {
              color: "#dfe4ebff",
            },
          },
          {
            featureType: "scenicspotsway",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "scenicspotsway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#dfe4ebff",
            },
          },
          {
            featureType: "universityway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#dfe4ebff",
            },
          },
          {
            featureType: "universityway",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "vacationway",
            elementType: "geometry.stroke",
            stylers: {
              color: "#dfe4ebff",
            },
          },
          {
            featureType: "vacationway",
            elementType: "geometry.fill",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "town",
            elementType: "labels.text",
            stylers: {
              fontsize: 18,
            },
          },
          {
            featureType: "town",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "town",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "highway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#c0792dff",
            },
          },
          {
            featureType: "highway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "nationalway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#c0792dff",
            },
          },
          {
            featureType: "nationalway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff60",
            },
          },
          {
            featureType: "provincialway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#c0792dff",
            },
          },
          {
            featureType: "provincialway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "cityhighway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#c0792dff",
            },
          },
          {
            featureType: "cityhighway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "arterial",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "arterial",
            elementType: "labels.text.fill",
            stylers: {
              color: "#c0792dff",
            },
          },
          {
            featureType: "arterial",
            elementType: "labels.text",
            stylers: {
              fontsize: 24,
            },
          },
          {
            featureType: "cityhighway",
            elementType: "labels.text",
            stylers: {
              fontsize: 24,
            },
          },
          {
            featureType: "provincialway",
            elementType: "labels.text",
            stylers: {
              fontsize: 24,
            },
          },
          {
            featureType: "nationalway",
            elementType: "labels.text",
            stylers: {
              fontsize: 24,
            },
          },
          {
            featureType: "highway",
            elementType: "labels.text",
            stylers: {
              fontsize: 24,
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "companylabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "companylabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "companylabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "lifeservicelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "lifeservicelabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "lifeservicelabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "carservicelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "carservicelabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "carservicelabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "financelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "financelabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "financelabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "tertiaryway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "tertiaryway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "tertiaryway",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "fourlevelway",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "fourlevelway",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "fourlevelway",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "local",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "local",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "local",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "companylabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "lifeservicelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "carservicelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "financelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "manmade",
            elementType: "geometry",
            stylers: {
              color: "#f5f6f7ff",
            },
          },
          {
            featureType: "subway",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "12",
            },
          },
          {
            featureType: "subway",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "13",
            },
          },
          {
            featureType: "subway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "12",
            },
          },
          {
            featureType: "subway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "13",
            },
          },
          {
            featureType: "subwaylabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,13",
              level: "13",
            },
          },
          {
            featureType: "subwaylabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,13",
              level: "13",
            },
          },
          {
            featureType: "subwaylabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,13",
              level: "13",
            },
          },
          {
            featureType: "railway",
            elementType: "geometry",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "scenicspotslabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "10",
            },
          },
          {
            featureType: "scenicspotslabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "11",
            },
          },
          {
            featureType: "scenicspotslabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "12",
            },
          },
          {
            featureType: "scenicspotslabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "13",
            },
          },
          {
            featureType: "scenicspotslabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "14",
            },
          },
          {
            featureType: "scenicspotslabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "15",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "10",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "11",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "12",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "13",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "14",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "15",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "10",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "11",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "12",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "13",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "14",
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "10,15",
              level: "15",
            },
          },
          {
            featureType: "district",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "district",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "city",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "city",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "city",
            elementType: "labels.icon",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "country",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "country",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "continent",
            elementType: "labels.text.fill",
            stylers: {
              color: "#a77726ff",
            },
          },
          {
            featureType: "continent",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "medicallabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "13",
            },
          },
          {
            featureType: "medicallabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "14",
            },
          },
          {
            featureType: "medicallabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "15",
            },
          },
          {
            featureType: "medicallabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "16",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "13",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "14",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "15",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "16",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "13",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "14",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "15",
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "16",
            },
          },
          {
            featureType: "entertainmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "12",
            },
          },
          {
            featureType: "entertainmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "13",
            },
          },
          {
            featureType: "entertainmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "14",
            },
          },
          {
            featureType: "entertainmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "15",
            },
          },
          {
            featureType: "entertainmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "16",
            },
          },
          {
            featureType: "entertainmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "17",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "12",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "13",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "14",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "15",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "16",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "17",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "12",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "13",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "14",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "15",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "16",
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,17",
              level: "17",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "estatelabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "13",
            },
          },
          {
            featureType: "estatelabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "14",
            },
          },
          {
            featureType: "estatelabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "15",
            },
          },
          {
            featureType: "estatelabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "16",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "13",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "14",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "15",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "16",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "13",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "14",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "15",
            },
          },
          {
            featureType: "estatelabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,16",
              level: "16",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "businesstowerlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "12",
            },
          },
          {
            featureType: "businesstowerlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "13",
            },
          },
          {
            featureType: "businesstowerlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "14",
            },
          },
          {
            featureType: "businesstowerlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "15",
            },
          },
          {
            featureType: "businesstowerlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "16",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "12",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "13",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "14",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "15",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "16",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "12",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "13",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "14",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "15",
            },
          },
          {
            featureType: "businesstowerlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,16",
              level: "16",
            },
          },
          {
            featureType: "governmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "13",
            },
          },
          {
            featureType: "governmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "14",
            },
          },
          {
            featureType: "governmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "15",
            },
          },
          {
            featureType: "governmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "16",
            },
          },
          {
            featureType: "governmentlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "17",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "13",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "14",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "15",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "16",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "17",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "13",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "14",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "15",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "16",
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,17",
              level: "17",
            },
          },
          {
            featureType: "restaurantlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "13",
            },
          },
          {
            featureType: "restaurantlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "14",
            },
          },
          {
            featureType: "restaurantlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "15",
            },
          },
          {
            featureType: "restaurantlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "16",
            },
          },
          {
            featureType: "restaurantlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "17",
            },
          },
          {
            featureType: "restaurantlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "18",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "13",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "14",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "15",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "16",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "17",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "18",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "13",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "14",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "15",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "16",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "17",
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "13,18",
              level: "18",
            },
          },
          {
            featureType: "hotellabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "14",
            },
          },
          {
            featureType: "hotellabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "15",
            },
          },
          {
            featureType: "hotellabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "16",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 22,
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "14",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 22,
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "15",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 22,
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "16",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "14",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "15",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "16",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "14",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "15",
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "14,16",
              level: "16",
            },
          },
          {
            featureType: "shoppinglabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "11",
            },
          },
          {
            featureType: "shoppinglabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "12",
            },
          },
          {
            featureType: "shoppinglabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "13",
            },
          },
          {
            featureType: "shoppinglabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "14",
            },
          },
          {
            featureType: "shoppinglabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "15",
            },
          },
          {
            featureType: "shoppinglabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "16",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "11",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "12",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "13",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "14",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "15",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "16",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "11",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "12",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "13",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "14",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "15",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,16",
              level: "16",
            },
          },
          {
            featureType: "shoppinglabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "hotellabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "restaurantlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "governmentlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "companylabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 24,
            },
          },
          {
            featureType: "entertainmentlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "medicallabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "scenicspotslabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "airportlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffffff",
            },
          },
          {
            featureType: "manmade",
            elementType: "labels.text.fill",
            stylers: {
              color: "#9ca0a3ff",
            },
          },
          {
            featureType: "manmade",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00",
            },
          },
          {
            featureType: "education",
            elementType: "labels",
            stylers: {
              visibility: "on",
            },
          },
          {
            featureType: "transportationlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "12",
            },
          },
          {
            featureType: "transportationlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "13",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "12",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "13",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "12",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "12,13",
              level: "13",
            },
          },
          {
            featureType: "educationlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "11",
            },
          },
          {
            featureType: "educationlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "12",
            },
          },
          {
            featureType: "educationlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "13",
            },
          },
          {
            featureType: "educationlabel",
            stylers: {
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "14",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "11",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "12",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "13",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "14",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "11",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "12",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "13",
            },
          },
          {
            featureType: "educationlabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
              curZoomRegionId: "0",
              curZoomRegion: "11,14",
              level: "14",
            },
          },
          {
            featureType: "transportationlabel",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "manmade",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "scenicspots",
            elementType: "labels.text.fill",
            stylers: {
              color: "#ab76b6ff",
            },
          },
          {
            featureType: "scenicspots",
            elementType: "labels.text",
            stylers: {
              fontsize: 23,
            },
          },
          {
            featureType: "water",
            elementType: "labels.text",
            stylers: {
              fontsize: 30,
            },
          },
        ],
      },
    };
  },
  mounted() {},
  created() {
    this.getRiverwayData();
  },
  methods: {
    updatePolylinePath(e) {
      this.siteVos.polylinePath = e.target.getPath();
    },
    clickPolyline(e){
      console.log('1111111111111111111',e);
    },
    // 河道储水
    getRiverwayData() {
      this.$get("/reachWaterYield/selectReachWaterYieldListAll", {}).then(
        (res) => {
          console.log("res===", res);
          this.gridData = res.data;
          res.data.forEach((item) => {
            this.siteVos.push({
              currentLevel: "0.0",
              deviceStatus: 1,
              electricityAlarmStatus: 0,
              gateLevel: null,
              id: item.id,
              lat: item.upstreamLatitude,
              latitude: null,
              lng: item.upstreamLongitude,
              longitude: null,
              siteName: item.reachName,
              waterLevelAlarmStatus: 0,
            });
            this.siteVos.push({
              currentLevel: "0.0",
              deviceStatus: 1,
              electricityAlarmStatus: 0,
              gateLevel: null,
              id: item.id,
              lat: item.downstreamLatitude,
              latitude: null,
              lng: item.downstreamLongitude,
              longitude: null,
              siteName: item.reachName,
              waterLevelAlarmStatus: 0,
            });
          });
          this.siteVos.polylinePath = [
            { lng: 114.863684, lat: 34.900173 },
            { lng: 114.854055, lat: 34.896265 },
            { lng: 114.846653, lat: 34.880573 },
            { lng: 114.816757, lat: 34.878973 },
            { lng: 114.799509, lat: 34.877434 },
            { lng: 114.821356, lat: 34.849829 },
            { lng: 114.862103, lat: 34.834424 },
            { lng: 114.803246, lat: 34.804078 },
            { lng: 114.889879, lat: 34.731577 },
            { lng: 114.88925, lat: 34.72367 },
          ];
        }
      );
      // getRiverwayDataList().then(res=>{
      //   // this.gridData=res.data
      // })
    },
    // 自定义经纬度高亮区域 根据自定义经纬度展示其余遮罩
    handler({ BMap, map }) {
      this.map = map;
      map.setMapStyleV2(this.mapStyle);
      this.getBoundary(this.map, "兰考县");
    },
    getBoundary(map, district) {
      var bdary = new BMap.Boundary();
      bdary.get(district, function (rs) {
        // 获取行政区域
        // map.clearOverlays(); //清除地图覆盖物
        var count = rs.boundaries.length; // 行政区域的点有多少个
        if (count === 0) {
          /* alert('未能获取当前输入行政区域'); */
          return;
        }
        const EN_JW = "180, 90;";
        const NW_JW = "-180,  90;";
        const WS_JW = "-180, -90;";
        const SE_JW = "180, -90;";
        // 东南西北四个角添加一个覆盖物
        const ply1 = new BMap.Polygon(
          rs.boundaries[0] + SE_JW + SE_JW + WS_JW + NW_JW + EN_JW + SE_JW,
          {
            strokeColor: "none",
            fillColor: "#F5F7FA",
            fillOpacity: 1,
            strokeOpacity: 1,
          }
        );
        map.addOverlay(ply1);
        var pointArray = [];
        for (var i = 0; i < count; i++) {
          var ply = new BMap.Polygon(rs.boundaries[i], {
            strokeWeight: 2, // 设置多边形边线线粗
            strokeOpacity: 1, // 设置多边形边线透明度0-1
            StrokeStyle: "dashed", // 设置多边形边线样式为实线或虚线，取值 solid 或 dashed
            strokeColor: "#009944", // 设置多边形边线颜色
            fillColor: "#8BFFCC", // 设置多边形填充颜色
            fillOpacity: 0.13, // 设置多边形填充颜色透明度0-1  注：标红的地放你们可以去掉看一下效果，自己体验一下
          }); // 建立多边形覆盖物
          // map.addOverlay(ply); // 添加覆盖物
          // pointArray = pointArray.concat(ply.getPath())
        }
        // map.setViewport(pointArray) // 调整视野
      });
    },
    getClickInfo(e) {},
  },
};
</script>

<style lang="scss" scoped>
.map-checkbox {
  border: 3px solid #0196ff;
  display: flex;
  border-radius: 30px;
  padding: 3px;
  position: absolute;
  top: 5%;
  left: 2%;
  z-index: 4;
  opacity: 1;
}
.icoQuan {
  position: absolute;
  top: 6.1%;
  left: 9.4%;
  display: flex;
  width: 240px;
  opacity: 1;
  div {
    flex: 1;
    img {
      position: relative;
      z-index: 5;
    }
  }
}
::v-deep.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 20px;
  padding: 8px 15px 8px 15px;
}
::v-deep .el-radio-button__inner {
  border-radius: 20px;
  padding: 8px 15px 8px 35px;
  border: none;
}
::v-deep.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 20px;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 20px;
}

// 状态
.map-data-tip {
  border: 1px solid #0196ff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  width: 130px;
  max-height: 300px;
  height: 100px;
  padding: 10px;
  position: absolute;
  top: 5%;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  transform: translateX(-50%);
  display: flex;
  color: #434343;
  z-index: 5;
  opacity: 1;
  //  flex-direction: column;
  // justify-content: space-around;
}
.typeOne {
  margin-top: 8px;
  padding-right: 15px;
  span {
    font-size: 15px;
    font-weight: bold;
  }
}
.tip-type {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    //margin: 5px ;
    padding-right: 10px;
  }
  .gray {
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #676767;
  }
  .red {
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #f14d4d;
  }
  .green {
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #3d7ef2;
  }
}
.Map {
  height: calc(986 / 1080 * 100vh);
  position: relative;
  // 雨情状态
  .rainStatus {
    position: absolute;
    top: calc(20 / 1920 * 100vw);
    left: calc(20 / 1920 * 100vw);
    width: calc(346 / 1920 * 100vw);
    /*height: calc(300 / 1080 * 100vh);*/
    opacity: 0.8;
    border: calc(2 / 1920 * 100vw) solid #0196ff;
    .title {
      margin-top: calc(5 / 1080 * 100vh);
      position: relative;
      height: calc(50 / 1080 * 100vh);
      .line::after {
        content: "";
        width: calc(3 / 1920 * 100vw);
        height: calc(17 / 1080 * 100vh);
        background-color: #3669ab;
        position: absolute;
        left: calc(17 / 1920 * 100vw);
        top: calc(13 / 1080 * 100vh);
      }
      .title-text {
        height: 100%;
        text-indent: calc(34 / 1920 * 100vw);
        line-height: calc(44 / 1080 * 100vh);
        color: #434343;
      }
    }
    .indexLlj {
      padding: 5px 12px;
      margin-bottom: 12px;
      .indexLlj_list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .indexLlj_item {
          display: flex;
          width: 50%;
          align-items: center;
          color: #333333;
          font-weight: 500;
          .img {
            display: block;
            width: 20px;
            height: 24px;
            margin-right: 4px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .a-display {
            display: block;
            margin-left: 5px;
            color: #333;
            font-weight: bold;
            font-size: calc(20 / 1920 * 100vw);
          }
          &:nth-child(3) {
            margin-top: 10px;
          }
          &:nth-child(4) {
            margin-top: 10px;
          }
        }
      }
    }
  }
  ul {
    li {
      position: relative;
      color: #434343;
      .text {
        width: calc(48 / 1920 * 100vw);
        font-size: calc(14 / 1920 * 100vw);
        position: relative;
        left: calc(2 / 1920 * 100vw);
        p {
          position: absolute;

          left: calc(10 / 1920 * 100vw);
          bottom: 0;
        }
      }
      .numberText {
        width: calc(34 / 1920 * 100vw);
        position: relative;
        bottom: calc(1 / 1080 * 100vh);
        // p {
        //   color: #333;
        //   font-weight: bold;
        //   font-size: calc(30 / 1920 * 100vw);
        //   position: relative;
        //   font-size: calc(20 / 1920 * 100vw);
        //   // bottom: 0;
        //   top: 5px;
        // }
      }
    }
  }
  .abul {
    position: absolute;
    top: 35px;
    right: 13%;
  }
  .ul-active {
    padding-bottom: 10px;
  }
  .map {
    width: 100%;
    height: 100%;
  }
}

::v-deep .RainBox {
  width: calc(250 / 1920 * 100vw);
  height: calc(116 / 1080 * 100vh);
  background: rgba(243, 248, 255, 0.5);
  border: calc(3 / 1920 * 100vw) solid #1f78ec;
  border-radius: 8px;
  button {
    margin: calc(10 / 1080 * 100vh) 0;
    margin-left: calc(12 / 1920 * 100vw);
    width: calc(224 / 1920 * 100vw);
    height: calc(30 / 1080 * 100vh);
    background: #1f78ec;
    border-radius: 8px;
    color: #dbebf9;
    font-size: calc(18 / 1920 * 100vw);
    font-weight: bold;
    line-height: calc(20 / 1080 * 100vh) !important;
  }
  .timer {
    margin-top: calc(3 / 1080 * 100vh);
  }
  span:nth-child(1) {
    margin-left: calc(12 / 1080 * 100vh);
    font-size: calc(16 / 1920 * 100vw);
    color: #666666;
  }
  span:nth-child(2) {
    font-size: calc(16 / 1920 * 100vw);
    font-weight: bold;
    color: #333333;
  }
}

::v-deep .rain {
  padding: 3px;
  width: 386px;
  height: 342px;
  background: #217acc;
  box-shadow: 0px 0px 13px 0px rgba(6, 0, 1, 0.27);
  border-radius: 16px;
  .rainBox {
    // margin: 5px;
    width: 386px;
    height: 342px;
    background: #f3f6f8;
    border-radius: 16px;
    .rainBox_title {
      height: 30px;
      .rainBox_title_num {
        width: 150px;
        color: #333;
        font-weight: 400;
      }
      .rainBox_title_text {
        width: 200px;
        color: #217acc;
      }
      .rainBox_title_img {
        // margin-left: 155px;
        width: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .polyin {
      height: 1px;
      background: #666666;
      opacity: 0.1;
      border-radius: 16px;
    }
    // 闸门信息
    .gate {
      height: 65px;
      .gate_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .gate_text {
        .ul_one {
          li {
            width: 50%;
          }
        }
        .ul_two {
          li {
            width: 50%;
          }
        }
      }
    }
    // 水文信息
    .waterMes {
      height: 65px;
      .waterMes_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .waterMes_text {
        margin-top: 5px;
      }
      .ul_one {
        li {
          width: 25%;
        }
      }
      .ul_two {
        li {
          width: 25%;
        }
      }
    }
    // 气象信息
    .mete {
      height: 45px;
      .mete_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .mete_text {
        margin-top: 6px;
        .ul_two {
          li {
            // width: 30%;
            // margin-left: 18px;
          }
        }
      }
    }
    .solar {
      height: 100px;
      .solar_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 2px;
        }
      }
      .solar_text {
        margin-top: 8px;
        .ul_two {
          li {
            height: 25px;
          }
        }
      }
    }
  }
}
.storage_Map_Table {
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #ffffff;
}
</style>
